<template>
    <LiefengContent>
        <template v-slot:title>电动自行车充电桩</template>
        <template v-slot:toolsbarLeft>
            <Select
                v-model="region"
                style="width: 120px; margin-right: 5px"
                placeholder="全部区"
                clearable
                @on-change="
                    e => {
                        changeRegion(e, 'streeList')
                    }
                "
            >
                <Option :value="item.orgCode" v-for="(item, index) in regionList" :key="index">{{ item.orgName }}</Option>
            </Select>
            <Select
                v-model="stree"
                style="width: 160px; margin-right: 5px"
                clearable
                placeholder="全部街道/乡镇"
                @on-change="
                    e => {
                        changeStree(e, 'projectList')
                    }
                "
            >
                <Option :value="item.orgCode" v-for="(item, index) in streeList" :key="index">{{ item.orgName }}</Option>
            </Select>
            <Select v-model="orgCode" style="width: 180px; margin-right: 5px" placeholder="全部社区" clearable>
                <Option :value="item.orgCode" v-for="(item, index) in projectList" :key="index">{{ item.orgName + "[" + item.orgCode + "]" }}</Option>
            </Select>
            <Input v-model.trim="keyword" placeholder="关键词" style="width: 160px; margin-right: 5px"></Input>
            <Button
                type="info"
                @click="
                    () => {
                        getSceneData(1)
                    }
                "
            >
                查询
            </Button>
            <Button
                type="default"
                @click="
                    () => {
                        region = stree = orgCode = keyword = ''
                        getSceneData(1)
                    }
                "
            >
                清空条件
            </Button>
        </template>
        <template v-slot:toolsbarRight>
            <Button type="info" @click="append({})">新增</Button>
            <Button type="error" @click="deleteData">删除</Button>
            <!-- <Button type="info" @click="importExcelBtn">导入</Button> -->
        </template>
        <template v-slot:contentArea>
            <div class="leftMenu">
                <LiefengTable
                    :talbeColumns="tableColumns"
                    :tableData="menuList"
                    :loading="loading"
                    :fixTable="true"
                    :curPage="page"
                    :total="total"
                    :pagesizeOpts="[20, 30, 50, 100]"
                    :page-size="pageSize"
                    @hadlePageSize="hadlePageSize"
                    @tableSelect="onSelect"
                ></LiefengTable>
            </div>
            <div class="rightEdit">
                <div v-if="JSON.stringify(currentFormData) !== '{}'" style="height:100%;overflow:hidden">
                    <div style="height: 50px; position: fixed; right: 0; width: 600px; z-index: 100; background: #f9f9f9">
                        <div style="font-weight: bold; line-height: 50px; font-size: 18px; padding-left: 20px;display:flex;">{{ currentFormData.id ? "修改" : "新增" }}
                            <Button style="margin-left:auto;margin-right:10px;margin-top:10px" type="success" icon="ios-share-outline" @click="submit">保存</Button>
                        </div>
                        
                    </div>
                    <div style="position: relative; height: 60px"></div>
                    <Form :model="currentFormData" :label-width="120" :rules="validateForm" class="form" ref="form">
                        <FormItem label="ID" v-if="currentFormData.id">
                            <Input disabled v-model.trim="currentFormData.id"></Input>
                        </FormItem>
                        <FormItem label="名称" prop="name">
                            <Input v-model.trim="currentFormData.name" placeholder="请输入名称" maxlength="50"></Input>
                        </FormItem>
                        <FormItem label="地址" prop="address">
                            <Input v-model.trim="currentFormData.address" placeholder="请输入地址"></Input>
                        </FormItem>
                        <FormItem label="经纬度" prop="lng">
                            <Input v-model.trim="currentFormData.lng" placeholder="经度" style="width: 120px; margin-right: 20px"></Input>
                            <Input v-model.trim="currentFormData.lat" placeholder="纬度" style="width: 120px; margin-right: 20px"></Input>
                            <Button @click="mapFn(true)">地图选点</Button>
                        </FormItem>

                        <FormItem label="绑定社区">
                            <Select
                                v-model="currentFormData.region"
                                style="width: 100px; margin-right: 5px"
                                placeholder="全部区"
                                @on-change="
                                    e => {
                                        changeRegion(e, 'streeList1')
                                    }
                                "
                            >
                                <Option :value="item.orgCode" v-for="(item, index) in regionList" :key="index">{{ item.orgName }}</Option>
                            </Select>
                            <Select
                                v-model="currentFormData.stree"
                                style="width: 150px; margin-right: 5px"
                                placeholder="全部街道/乡镇"
                                @on-change="
                                    e => {
                                        changeStree(e, 'projectList1')
                                    }
                                "
                            >
                                <Option :value="item.orgCode" v-for="(item, index) in streeList1" :key="index">{{ item.orgName }}</Option>
                            </Select>
                            <Select v-model="currentFormData.orgcode" style="width: 160px; margin-right: 5px" placeholder="全部社区" clearable>
                                <Option :value="item.orgCode" v-for="(item, index) in projectList1" :key="index">{{ item.orgName }}</Option>
                            </Select>
                        </FormItem>

                        <FormItem label="营业时段" prop="amtimerange">
                            上午：
                            <TimePicker
                                v-model.trim="currentFormData.amtimerange"
                                format="HH:mm"
                                type="timerange"
                                placement="bottom-end"
                                placeholder="选择上午营业时段"
                                @on-change="chaneTime"
                            ></TimePicker>
                        </FormItem>
                        <FormItem label="" prop="pmtimerange">
                            下午：
                            <TimePicker
                                v-model.trim="currentFormData.pmtimerange"
                                format="HH:mm"
                                type="timerange"
                                placement="bottom-end"
                                placeholder="选择下午营业时段"
                                @on-change="chaneTime"
                            ></TimePicker>
                        </FormItem>
                        <FormItem label="营业状态" prop="status">
                            <i-switch v-model.trim="currentFormData.status" size="large">
                                <span slot="open">营业</span>
                                <span slot="close">休业</span>
                            </i-switch>
                        </FormItem>
                        <FormItem label="直播号" prop="livecode">
                            <Input v-model.trim="currentFormData.livecode" placeholder="请输入直播号码"></Input>
                        </FormItem>
                        <FormItem label="充电位">
                            <Input style="width:300px" type="Number" v-model.trim="currentFormData.chargingPosition" placeholder="请填写数字"></Input>
                            <span style="margin-left:5px">个</span>
                        </FormItem>
                        <FormItem label="停车位">
                            <Input style="width:300px"  type="Number" v-model.trim="currentFormData.parkingSpace" placeholder="请填写数字"></Input>
                            <span style="margin-left:5px">个</span>
                        </FormItem>
                        <FormItem label="收费情况" prop="chargeornot">
                            <RadioGroup v-model="currentFormData.chargeornot">
                                <Radio label="收费"></Radio>
                                <Radio label="免费"></Radio>
                                <Radio label="不确定"></Radio>
                            </RadioGroup>
                        </FormItem>
                        <FormItem v-if="currentFormData.chargeornot && currentFormData.chargeornot != '' && currentFormData.chargeornot.indexOf('收费') > -1">
                            <Input v-model.trim="currentFormData.price" placeholder="如：1元/时"></Input>
                        </FormItem>
                        <FormItem label="描述">
                            <Input type="textarea" v-model.trim="currentFormData.content" placeholder="请输入50字内描述"></Input>
                        </FormItem>
                        <FormItem label="现场图">
                            <LiefengUpload ref="LiefengUploadIcon" v-if="showImage" accept=".jpg,.png,.gif,.jpeg" :format="['jpg', 'png', 'jpeg', 'gif']" :defaultList='currentFormData.img' :showView="true"></LiefengUpload>
                        </FormItem>
                    </Form>
                </div>
                <div v-else class="noContent">新增/点击列表名称修改</div>
            </div>

            <!-- 导入 -->
            <LiefengModal title="导入" width="40%" height="240px" :value="exportExcel.status" @input="excelModalData" class="export-modal">
                <template v-slot:contentarea>
                    <LiefengUploadExcel
                        style="margin: 20px 0"
                        :showDownModal="{ isShow: true, downLink: './excelfiles/15mintesttemplate.xlsx', downName: '十五分钟核酸圈导入模板' }"
                        :action="exportExcel.exportAction"
                        :uploadData="uploadData"
                        :isShow="exportExcel.showExcel"
                        @success="excelSuccess"
                    ></LiefengUploadExcel>
                </template>
            </LiefengModal>

            <LiefengModal class="map" :fullscreen="false" width="80%" height="550px" title="选择经纬度" :value="mapStatus" @input="mapFn">
                <template v-slot:contentarea>
                    <div style="width: 400px; position: absolute; margin: 10px; z-index: 100; display: flex; background: #ffffff; padding: 10px; border-radius: 10px">
                        <Input v-model="keyword" style="width: 100%; margin-right: 5px" placeholder="请输入地名"></Input>
                        <Button type="primary" @click="setPlace(keyword)">查询</Button>
                    </div>
                    <baidu-map
                        :center="pageCenter.lng == null ? { lng: 113.263, lat: 23.115 } : pageCenter"
                        :zoom="zoom"
                        @ready="handler"
                        :ak="$store.state.baiduAk"
                        :clicking="true"
                        @click="clickMap"
                        :scroll-wheel-zoom="true"
                    >
                        <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
                        <bm-view style="width: 100%; height: 470px; flex: 1"></bm-view>
                    </baidu-map>
                </template>
                <template #toolsbar>
                    <Button type="info" style="margin-right: 10px" @click="mapFn(false)">取消</Button>
                    <Button type="primary" @click="selectMap">确定</Button>
                </template>
            </LiefengModal>
        </template>
    </LiefengContent>
</template>

<script>
//@route('/test15minmanage')
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
import LiefengModal from "@/components/LiefengModal"
import LiefengUploadExcel from "@/components/LiefengUploadExcel"
import { BaiduMap, BmMarker, BmView, BmNavigation } from "vue-baidu-map"
import LiefengUpload from '@/components/LiefengUpload';
export default {
    components: {
        LiefengContent,
        LiefengTable,
        LiefengModal,
        LiefengUploadExcel,
        BaiduMap,
        BmMarker,
        BmView,
        BmNavigation,
        LiefengUpload
    },
    data() {
        return {
            mapStatus: false,
            zoom: 16,
            pageCenter: {},
            selectedLocation: {},
            BMap: null,
            map: null,
            keyword: "",
            menuList: [],
            selection: [],
            stree: "",
            orgCode: "",
            region: "",
            regionList: [],
            streeList: [],
            projectList: [],
            streeList1: [],
            projectList1: [],
            page: 1,
            pageSize: 20,
            total: 0,
            loading: false,
            tableColumns: [
                {
                    type: "selection",
                    width: 60,
                    key: "id",
                    align: "center",
                },
                {
                    title: "充电点名称",
                    width: 250,
                    key: "name",
                    align: "center",
                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                style: { color: "blue", cursor: "pointer" },
                                on: {
                                    click: () => {
                                        this.select(params.row.id)
                                    },
                                },
                            },
                            params.row.name
                        )
                    },
                },
                {
                    title: "充电点地址",
                    minWidth: 200,
                    key: "address",
                    align: "center",
                },
                {
                    title: "绑定社区",
                    width: 150,
                    key: "orgcode",
                    align: "center",
                    render: (h, params) => {
                        return h("span", {}, params.row.orginfo && JSON.parse(params.row.orginfo) && JSON.parse(params.row.orginfo).projectname ? JSON.parse(params.row.orginfo).projectname : "未绑定")
                    },
                },
                {
                    title: "营业时段",
                    width: 125,
                    key: "orgcode",
                    align: "center",
                    render: (h, params) => {
                        return h(
                            "span",
                            {},
                            params.row.starttime && params.row.endtime
                                ? params.row.starttime + "~" + (params.row.amendtime || "12:00") + " " + (params.row.pmstarttime || "14:00") + "~" + params.row.endtime
                                : "未设定"
                        )
                    },
                },
                {
                    title: "营业状态",
                    width: 100,
                    align: "center",
                    render: (h, params) => {
                        return h("i-Switch", {
                            props: {
                                size: "large",
                                value: params.row.status == 1,
                            },
                            scopedSlots: {
                                open: () => h("span", "营业"),
                                close: () => h("span", "休业"),
                            },
                            on: {
                                "on-change": () => {
                                    this.changeStatus(params.row)
                                },
                            },
                        })
                    },
                },
                {
                    title: "添加人",
                    minWidth: 200,
                    key: "creatorName",
                    align: "center",
                },
                {
                    title: "添加账号",
                    minWidth: 200,
                    key: "creatorAccount",
                    align: "center",
                },
                {
                    title: "添加时间",
                    key: "gmtCreate",
                    width: 170,
                    align: "center",
                    render : (h,params) =>{
                        return h ('div',{},params.row.gmtCreate ?params.row.gmtCreate = this.$core.formatDate(
                            new Date(params.row.gmtCreate),
                            "yyyy-MM-dd hh:mm:ss"
                        ):'')
                    }
                },
                // {
                //     title: '操作',
                //     width:140,
                //     align: "center",
                //     render: (h, params) => {
                //         return h("div", [
                //             h(
                //                 "Button",
                //                 {
                //                 props: {
                //                     size: "small",
                //                     type: "info",
                //                 },
                //                 on: {
                //                     click: () => {
                //                         this.delete(params.row.id)
                //                     },
                //                 },
                //                 },
                //                 "修改"
                //             ),
                //             h(
                //                 "Button",
                //                 {
                //                 props: {
                //                     size: "small",
                //                     type: "error",
                //                 },
                //                 style:{
                //                    margin:"5px"
                //                 },
                //                 on: {
                //                     click: () => {
                //                         this.delete(params.row.id)
                //                     },
                //                 },
                //                 },
                //                 "删除"
                //             ),
                //         ]);
                //     },
                // }
            ],
            buttonProps: {
                type: "default",
                size: "small",
            },
            currentFormData: {},
            defaultList: [],
            validateForm: {
                name: [{ required: true, message: "请输入名称", trigger: "blur" }],
                address: [
                    {
                        required: true,
                        message: "请输入地址",
                        trigger: "blur",
                    },
                ],
            },
            exportExcel: {
                status: false,
                exportAction: "",
                showExcel: 0,
            },
            showImage:false
        }
    },
    methods: {
        // 图片上传成功
        uploadSuccess(val){
            this.currentFormData.img = val
        },
        // 点击导入按钮
        importExcelBtn() {
            this.exportExcel.status = true
            this.exportExcel.showExcel++
            //  this.exportExcel.exportAction = "http://localhost:808/api/o2o/test15min/import";
            this.exportExcel.exportAction = window.vue.getProxy()["/gateway"].target + "/apps/o2o/api/o2o/test15min/import"
        },
        // 关闭导入弹窗事件
        excelModalData(status) {
            this.exportExcel.status = status
        },
        //导入成功的回调
        excelSuccess() {
            this.exportExcel.status = false
            this.getSceneData(1)
        },
        // 区
        selectRegion(code) {
            if (code == null || code == "") return
            this.getPulishData(code, "regionList")
        },
        // 区选择
        changeRegion(code, type) {
            // console.log(code,type);
            if (type == "streeList1") {
                this.currentFormData.stree = ""
                this.currentFormData.orgcode = ""
                this.streeList1 = []
                this.projectList1 = []
            } else {
                this.stree = ""
                this.orgCode = ""
                this.streeList = []
                this.projectList = []
            }

            this.getPulishData(code, type)
        },
        // 街道选择
        changeStree(code, type) {
            if (type == "projectList1") {
                this.currentFormData.orgcode = ""
                this.projectList1 = []
            } else {
                this.orgCode = ""
                this.projectList = []
            }
            this.getPulishData(code, type)
        },
        // 获取范围列表
        getPulishData(code, list) {
            this.$Message.destroy()
            this.$Message.loading({
                content: "正在加载数据，请稍等...",
                duration: 0,
            })
            this.$get("/datamsg/api/pc/staff/selectCascadeDataScope", {
                orgCode: code,
                oemCode: parent.vue.oemInfo.oemCode,
                orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
            })
                .then(res => {
                    //  console.log(res.dataList,code);
                    this.$Message.destroy()
                    if (res.code == 200) {
                        this[list] = res.dataList
                    }
                })
                .catch(err => {
                    this.$Message.destroy()
                })
        },

        submit() {
            this.currentFormData.img = this.$refs.LiefengUploadIcon.uploadList[0] ?  this.$refs.LiefengUploadIcon.uploadList[0].url : '';
            this.$refs.form.validate(status => {
                if (status) {
                    if (!this.currentFormData.lng || !this.currentFormData.lat) {
                        this.$Message.error({
                            background: true,
                            content: "经纬度必填",
                        })
                        return
                    }

                    let orginfo = {
                        region: this.currentFormData.region,
                        stree: this.currentFormData.stree,
                        project: this.currentFormData.orgcode,
                        projectname: this.currentFormData.orgcode ? this.projectList1.find(item => item.orgCode == this.currentFormData.orgcode).orgName : "",
                    }
                    var cFormData = JSON.parse(JSON.stringify(this.currentFormData))
                    cFormData.orginfo = JSON.stringify(orginfo)
                    if (cFormData.amtimerange && cFormData.amtimerange.length >= 2) {
                        cFormData.starttime = cFormData.amtimerange[0]
                        cFormData.amendtime = cFormData.amtimerange[1]
                    }
                    if (cFormData.pmtimerange && cFormData.pmtimerange.length >= 2) {
                        cFormData.pmstarttime = cFormData.pmtimerange[0]
                        cFormData.endtime = cFormData.pmtimerange[1]
                    }
                    if (cFormData.status == true) {
                        cFormData.status = 1
                    } else {
                        cFormData.status = 0
                    }
                    if (cFormData.chargeornot == "收费") {
                        cFormData.chargeornot = 1
                    } else if (cFormData.chargeornot == "免费") {
                        cFormData.chargeornot = 2
                    } else {
                        cFormData.chargeornot = 0
                    }
                    cFormData.config = JSON.stringify({
                        price : this.currentFormData.chargeornot && this.currentFormData.chargeornot != '' && this.currentFormData.chargeornot.indexOf('收费') > -1 ? this.currentFormData.price : '',
                        parkingSpace: this.currentFormData.parkingSpace && this.currentFormData.parkingSpace != '' ? this.currentFormData.parkingSpace : '',
                        chargingPosition: this.currentFormData.chargingPosition && this.currentFormData.chargingPosition != '' ? this.currentFormData.chargingPosition : '',
                        img:this.currentFormData.img && this.currentFormData.img != '' ? this.currentFormData.img : ''
                    })
                    delete cFormData.price
                    delete cFormData.parkingSpace
                    delete cFormData.chargingPosition
                    delete cFormData.img
                    delete cFormData.gmtCreate

                    console.log('新增参数',cFormData);
                    this.$post("/gateway/apps/o2o/api/o2o/test15min/save", {
                        ...cFormData,
                        type:'2',
                        oemCode: parent.vue.oemInfo.oemCode,
                    })
                        .then(res => {
                            if (res.code == 200) {
                                this.$Message.success({
                                    background: true,
                                    content: "保存成功",
                                })
                                if (!this.currentFormData.id) {
                                    this.$set(this.currentFormData, "id", res.data.id)
                                }
                                this.getSceneData(this.page)
                            } else {
                                this.$Message.error({
                                    background: true,
                                    content: res.desc,
                                })
                            }
                        })
                        .catch(err => {
                            console.log(err)
                            this.$Message.error({
                                background: true,
                                content: "保存失败，请联系管理员处理",
                            })
                        })
                }
            })
        },

        append(data) {
            this.defaultList = []
            this.currentFormData = {
                id: data.id,
                status: true,
                chargeornot: "不确定",
                img:[]
            },
            this.showImage = false
            this.$nextTick(()=>{
                this.showImage = true
            })
        },
        getBCTitle(id, obj) {
            if (obj.id == id) {
                this.bcTitle.unshift(obj.title)
                this.getBCTitle(obj.parentId, this.menuList[0])
            } else if (obj.children && obj.children.length > 0) {
                for (let k in obj.children) {
                    this.getBCTitle(id, obj.children[k])
                }
            }
        },
        select(id) {
            this.showImage = false
            this.$nextTick(()=>{
                this.showImage = true
            })
            if (id) {
                this.currentFormData = {}
                this.$get("/gateway/apps/o2o/api/o2o/test15min/viewById", {
                    id: id,
                    oemCode: parent.vue.oemInfo.oemCode,
                })
                    .then(res => {
                        if (res.code == 200 && res.data) {
                            var cFormData = res.data
                            cFormData.orginfo = cFormData.orginfo ? JSON.parse(cFormData.orginfo) : {}
                            if (!cFormData.region) cFormData.region = cFormData.orginfo.region
                            if (!cFormData.stree) cFormData.stree = cFormData.orginfo.stree
                            this.changeRegion(cFormData.region, "streeList1")
                            this.changeStree(cFormData.stree, "projectList1")
                            if (cFormData.orginfo && this.projectList1.length == 0) this.projectList1 = [{ orgCode: cFormData.orginfo.project, orgName: cFormData.orginfo.projectname }]
                            if (cFormData.starttime && cFormData.amendtime) {
                                cFormData.amtimerange = [cFormData.starttime, cFormData.amendtime]
                            }
                            if (cFormData.pmstarttime && cFormData.endtime) {
                                cFormData.pmtimerange = [cFormData.pmstarttime, cFormData.endtime]
                            }
                            if (cFormData.status == 1) {
                                cFormData.status = true
                            } else {
                                cFormData.status = false
                            }
                            if (cFormData.chargeornot == 1) {
                                cFormData.chargeornot = "收费"
                            } else if (cFormData.chargeornot == 2) {
                                cFormData.chargeornot = "免费"
                            } else {
                                cFormData.chargeornot = "不确定"
                            }

                            if(cFormData.config && cFormData.config != ''){
                                let config = JSON.parse(cFormData.config)
                                if(cFormData.chargeornot.indexOf("收费") > -1){
                                    cFormData.price = config.price
                                    cFormData.chargingPosition = config.chargingPosition
                                    cFormData.parkingSpace = config.parkingSpace
                                    cFormData.img = [{name:'',url:config.img}]
                                }else{
                                    if(config.price && config.price != ''){
                                         cFormData.chargeornot = "收费"
                                          cFormData.price = config.price
                                    }else{
                                         cFormData.price = ''
                                    }
                                    cFormData.chargingPosition = config.chargingPosition
                                    cFormData.parkingSpace = config.parkingSpace
                                    cFormData.img = [{name:'',url:config.img}]
                                }
                            }

                            this.currentFormData = cFormData
                            this.$forceUpdate()
                        } else {
                            this.$Message.error({
                                background: true,
                                content: res.desc,
                            })
                        }
                    })
                    .catch(err => {
                        console.log(err)
                        this.$Message.error({
                            background: true,
                            content: "获取数据失败，请联系管理员处理",
                        })
                    })
            }
        },
        onSelect(selection) {
            this.selection = selection
        },
        deleteData() {
            if (this.selection && this.selection.length > 0) {
                // console.log(this.selection.map(item=>{return item.id}).join(","));
                this.$Modal.confirm({
                    title: "删除确认",
                    content: "是否删除选中的数据？",
                    onOk: () => {
                        this.$post("/gateway/apps/o2o/api/o2o/test15min/delete", {
                            id: this.selection
                                .map(item => {
                                    return item.id
                                })
                                .join(","),
                        })
                            .then(res => {
                                if (res.code == 200) {
                                    this.getSceneData(1)
                                } else {
                                    this.$Message.error({
                                        background: true,
                                        content: "删除出错：" + res.desc,
                                    })
                                }
                            })
                            .catch(err => {
                                this.$Message.error({
                                    background: true,
                                    content: "删除出措",
                                })
                            })
                    },
                })
            } else {
                this.$Message.error({
                    background: true,
                    content: "请先选择删除列",
                })
            }
        },
        changeStatus(data) {
            this.$post("/gateway/apps/o2o/api/o2o/test15min/updateStatus", {
                id: data.id,
                status: data.status == 1 ? 0 : 1,
            })
                .then(res => {
                    if (res.code == 200) {
                        this.$Message.info("更新成功")
                    } else {
                        this.$Message.error({
                            background: true,
                            content: "更新状态出错：" + res.desc,
                        })
                    }
                })
                .catch(err => {
                    this.$Message.error({
                        background: true,
                        content: "更新状态出错",
                    })
                })
        },
        hadlePageSize(obj) {
            this.pageSize = obj.pageSize
            this.getSceneData(obj.page)
        },
        getSceneData(page) {
            // if((this.region||this.stree)&&!this.orgCode){
            //     this.$Modal.warning({
            //         title:"区镇条件被忽略",
            //         content: "必须选择社区"
            //             });
            // }
            this.selection = []
            this.loading = true
            this.$get("/gateway/apps/o2o/api/o2o/test15min/query", {
                page: page,
                size: this.pageSize,
                keyword: this.keyword,
                orgCode: this.orgCode,
                region: this.region,
                stree: this.stree,
                oemCode: parent.vue.oemInfo.oemCode,
                type: 2,
            })
                .then(res => {
                    if (res.code == 200 && res.dataList) {
                        this.menuList = res.dataList
                        this.page = res.currentPage
                        this.pageSize = res.pageSize
                        this.total = res.maxCount
                    }
                    this.$Message.destroy()
                    this.loading = false
                })
                .catch(err => {
                    this.loading = false
                    this.$Message.destroy()
                    this.$Message.error({
                        background: true,
                        content: "获取数据失败，请联系管理员处理",
                    })
                })
        },
        // 点击了地图
        clickMap(e) {
            this.makerCenter({ lng: e.point.lng, lat: e.point.lat })
        },
        handler({ BMap, map }) {
            this.BMap = BMap
            this.map = map
        },
        makerCenter(point) {
            if (this.map) {
                this.selectedLocation = point
                this.map.clearOverlays()
                this.map.addOverlay(new this.BMap.Marker(point))
            }
        },
        mapFn(status) {
            this.mapStatus = status
            if (this.currentFormData.lng) {
                this.keyword = ""
                this.pageCenter = { lng: this.currentFormData.lng, lat: this.currentFormData.lat }
                this.makerCenter(this.pageCenter)
            } else if (this.currentFormData.address) {
                this.keyword = this.currentFormData.address
                this.setPlace(this.currentFormData.address)
            }
        },
        selectMap() {
            this.currentFormData.lng = this.selectedLocation.lng
            this.currentFormData.lat = this.selectedLocation.lat
            this.mapStatus = false
        },
        setPlace(myValue) {
            if (!myValue) return
            var that = this
            var local = new this.BMap.LocalSearch(this.map, {
                onSearchComplete: () => {
                    try {
                        var pp = local.getResults().getPoi(0).point
                        that.pageCenter = { lng: pp.lng, lat: pp.lat }
                        that.makerCenter(pp)
                    } catch (e) {}
                },
            })
            local.search(myValue)
        },
        chaneTime(e) {
            console.log(e)
        },
    },
    created() {
        this.getSceneData(1)
        this.selectRegion("4401")
    },
}
</script>

<style lang='less'>
.leftMenu {
    float: left;
    // position: fixed;
    // top: 60px;
    // left: 0px;
    width: calc(100% - 600px);
    // bottom: 0;
    background: #ffffff;
    // overflow: auto;
}
.rightEdit {
    float: left;
    // position: fixed;
    // top: 60px;
    height: 600px;
    width: 600px;
    // right: 0;
    bottom: 0;
    // overflow: auto;
    border-left: #ddd solid 1px;
}
.demo-tree-render .ivu-tree-title {
    width: calc(100% - 34px);
    padding: 10px;
    & > span {
        & > span:first-of-type {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: 70%;
            display: inline-block;
        }
    }
}
.demo-tree-render .ivu-tree-arrow {
    padding: 10px;
}
.ivu-breadcrumb {
    background-color: #fff;
    line-height: 36px;
    padding: 0 20px;
    margin-bottom: 10px;
    position: relative;
}
.form {
    padding:10px 30px 10px 10px;
    height: 100%;
    overflow: scroll;
}
.noContent {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9;
    color: #999;
    font-size: 36px;
}
.validate {
    &::before {
        content: "*";
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        font-size: 14px;
        color: #ed4014;
    }
}
.toolsbarRight{
    display: flex;
    padding-top: 10px;
}
</style>
